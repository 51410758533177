module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/home/runner/work/blog/blog/node_modules/gatsby-remark-images","id":"580fd6f0-a0f4-5cb7-ac28-2bea65acb525","name":"gatsby-remark-images","version":"6.25.0","modulePath":"/home/runner/work/blog/blog/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/home/runner/work/blog/blog/node_modules/gatsby-remark-copy-linked-files","id":"cf104666-f93f-5e9d-9c2e-1bd9003e1e14","name":"gatsby-remark-copy-linked-files","version":"5.25.0","modulePath":"/home/runner/work/blog/blog/node_modules/gatsby-remark-copy-linked-files/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/home/runner/work/blog/blog/node_modules/gatsby-remark-prismjs","id":"016a6a3d-da0d-5ced-9b80-82fa2774f4af","name":"gatsby-remark-prismjs","version":"6.25.0","modulePath":"/home/runner/work/blog/blog/node_modules/gatsby-remark-prismjs/index.js","pluginOptions":{"plugins":[],"classPrefix":"language-","inlineCodeMarker":null,"aliases":{"js":"javascript","ts":"typescript","py":"python","kt":"kotlin"},"showLineNumbers":true,"noInlineHighlight":true,"languageExtensions":[],"prompt":{},"escapeEntities":{}},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/blog/blog","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"language":"ko","openGraph":{"title":"Jinwoo's Blog","description":"서버 엔지니어가 글쓰기 연습을 위해 개발한 기술 블로그입니다. 다양한 주제를 포괄적으로 다룹니다.","type":"website","locale":"ko_KR","url":"https://bugoverdose.github.io/","site_name":"Jinwoo's Blog","images":[{"url":"/bugoverdose-open-graph.png","width":800,"height":400,"alt":"bugoverdose site main image"}]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jinwoo's Blog","short_name":"bugoverdose","start_url":"/","background_color":"#252525","theme_color":"#4949ff","display":"standalone","icon":"static/bugoverdose-logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a42af471667cabb5873d3fded693641a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-CWPVEYS18F"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
